<template>
  <div style="position: relative">
    <v-row style="position: relative; margin: auto; justify-content: center">
      <v-col style="position: relative; background: white; padding: 0" cols="7">
        <div
          style="
            margin-bottom: 10px;
            position: sticky;
            top: 60px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 5px;
            z-index: 1;
            background: white;
          "
        >
          <div
            @click="back"
            class="cart-back-icon"
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 3px;
            "
          >
            <v-icon
              class="icon"
              small
              style="transform: rotate(-90deg); margin-right: 5px"
              >mdi-triangle</v-icon
            >
            <span>Back</span>
          </div>
        </div>
        <div>
          <h5 style="font-weight: bold; padding-left: 5px">Keranjang</h5>
        </div>
        <div
          style="
            padding: 10px 6px;
            position: sticky;
            top: 83px;
            border-bottom: 4px solid rgba(219, 217, 217, 0.5);
            z-index: 1;
            background: white;
          "
        >
          <div
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <v-checkbox
              v-model="isSelectAll"
              @change="selectAll"
              style="width: 35px; height: 33px; margin: 0"
            ></v-checkbox>
            <span style="color: grey">Pilih Semua</span>
            <button
              style="
                font-size: 15px;
                margin-left: auto;
                font-weight: bold;
                color: rgb(44, 194, 253);
              "
              v-if="select.length !== 0 && isSelectAll"
              @click="remove"
            >
              Hapus
            </button>
          </div>
        </div>
        <div
          v-for="(item, index) in items"
          :key="index"
          style="
            border-bottom: 4px solid rgba(219, 217, 217, 0.5);
            position: relative;
            min-height: 25vh;
            padding: 10px 6px;
            display: flex;
            position: relative;
          "
        >
          <v-col md="12" style="padding: 0">
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
              >
                <v-checkbox
                  indeterminate-icon="mdi-square-rounded"
                  indeterminate
                  disabled
                  v-model="selectByVendor"
                  style="width: 35px; height: 33px; margin: 0"
                ></v-checkbox>
                <div>
                  <p style="margin: 0">{{ item.data[0].vendor.name }}</p>
                  <p
                    style="
                      margin: 0;
                      font-size: 10px;
                      color: rgba(0, 0, 0, 0.6);
                    "
                  >
                    {{ item.data[0].vendor.code }}
                  </p>
                </div>
              </div>

              <div
                v-for="(data, key) in item.data"
                :key="key"
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  width: 100%;
                  height: 145px;
                  margin-bottom: 10px;
                "
              >
                <v-checkbox
                  @change="selectOne"
                  v-model="select"
                  :value="data"
                  style="
                    width: 35px;
                    height: 33px;
                    margin: 0;
                    position: relative;
                    top: -15px;
                  "
                ></v-checkbox>
                <div style="width: 100%; height: 100%; padding-top: 5px">
                  <div style="display: flex; width: 100%; height: 105px">
                    <div
                      style="width: 15%; background: grey; border-radius: 7px"
                    ></div>
                    <div style="width: 85%; padding: 0 5px">
                      <div
                        style="display: flex; align-items: center; height: 21px"
                      >
                        <v-tooltip
                          bottom
                          color="#9FA8DA"
                          style="border: 1px solid black"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-toolbar-title
                              v-bind="attrs"
                              v-on="on"
                              style="
                                text-align: left;
                                font-size: 16px;
                                padding: 0;
                                width: 60%;
                              "
                            >
                              {{ data.product.name }}
                            </v-toolbar-title>
                          </template>
                          <span style="color: black">{{
                            data.product.name
                          }}</span>
                        </v-tooltip>
                        <v-divider class="mx-4" vertical></v-divider>
                        <v-toolbar-title
                          style="
                            width: 90px;
                            margin: 0 10px 0 0;
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.8);
                            font-weight: bold;
                            display: flex;
                            justify-content: space-between;
                          "
                        >
                          <span>Harga Terakhir</span> <span>:</span>
                        </v-toolbar-title>
                        <p style="margin: 0; padding: 0">
                          Rp. {{ price(data.last_price) }}
                        </p>
                        <!-- <v-divider class="mx-2" vertical></v-divider>
                        <v-toolbar-title
                          style="
                            width: 90px;
                            margin: 0 10px 0 0;
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.8);
                            font-weight: bold;
                            display: flex;
                            justify-content: space-between;
                          "
                        >
                          <span>Tgl. Digunakan</span> <span>:</span>
                        </v-toolbar-title>

                        <v-text-field
                          v-if="data.budget_edit"
                          v-model="data.raw_date"
                          style="height: 41px"
                          clearable
                          outlined
                          dense
                          type="date"
                          name="date"
                          step="1"
                        />
                        <p v-else style="margin: 0; padding: 0">
                          {{ convertDate(data.estimate_date_use) }}
                        </p> -->
                      </div>
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          color: rgba(0, 0, 0, 0.6);
                          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                        "
                      >
                        {{ data.product.code }}
                      </p>
                      <div style="display: flex">
                        <div style="width: 35%">
                          <v-toolbar-title
                            style="
                              margin: 0;
                              font-size: 12px;
                              color: rgba(0, 0, 0, 0.8);
                              font-weight: bold;
                            "
                          >
                            Harga Budget/ item
                          </v-toolbar-title>
                          <div>
                            <v-text-field
                              style="height: 40px"
                              v-if="data.budget_edit"
                              v-model="data.raw_budget"
                              outlined
                              dense
                              small
                            ></v-text-field>
                            <div
                              v-else
                              style="
                                padding: 0 0 10px 5px;
                                height: 40px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: 100%;
                              "
                            >
                              <p style="margin: 0; padding: 0">
                                {{data.currency != null ? data.currency.symbol : 'Rp'}}. {{ price(data.budget) }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <v-divider class="mx-2" vertical></v-divider>

                        <div style="width: 65%">
                          <v-toolbar-title
                            style="
                              margin: 0;
                              font-size: 12px;
                              color: rgba(0, 0, 0, 0.8);
                              font-weight: bold;
                            "
                          >
                            Deskripsi
                          </v-toolbar-title>
                          <div>
                            <v-text-field
                              style="height: 40px"
                              v-if="data.budget_edit"
                              v-model="data.raw_reason"
                              outlined
                              dense
                              small
                            ></v-text-field>
                            <div
                              v-else
                              style="
                                padding: 0 0 10px 5px;
                                height: 40px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: 100%;
                              "
                            >
                              <p style="margin: 0; padding: 0">
                                {{ data.reason }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      margin-top: 5px;
                      position: relative;
                      width: 100%;
                      height: 35px;
                      display: flex;
                      /* justify-content: space-between; */
                    "
                  >
                    <div
                      style="
                        height: 35px;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <div
                        class="d-flex justify-start align-start"
                        style="width: 90px"
                      >
                        <div v-if="data.budget_edit" style="">
                          <v-btn text small icon color="green lighten-2">
                            <v-icon
                              small
                              style="cursor: pointer"
                              color="green"
                              @click="iBudgetSave(index, key)"
                            >
                              mdi-content-save-outline</v-icon
                            >
                          </v-btn>
                          <v-btn text small icon color="red lighten-2">
                            <v-icon
                              small
                              style="cursor: pointer"
                              color="red"
                              @click="iBudgetCancel(index, key)"
                            >
                              mdi-close-circle-outline</v-icon
                            >
                          </v-btn>
                        </div>
                        <v-btn
                          v-else
                          text
                          icon
                          color="black lighten-2"
                          style="margin-right: 5px"
                          small
                        >
                          <v-icon
                            small
                            style="cursor: pointer"
                            color="black"
                            @click="iBudgetEdit(index, key)"
                          >
                            mdi-pencil</v-icon
                          >
                        </v-btn>

                        <v-btn text icon color="red lighten-2" small>
                          <v-icon
                            small
                            style="cursor: pointer"
                            color="red darken-4"
                            @click="iDelete(data, index, key)"
                          >
                            mdi-delete</v-icon
                          >
                        </v-btn>
                      </div>
                      <v-divider class="mx-2" vertical></v-divider>
                      <div
                        style="
                          width: 140px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <div
                          style="text-align: center"
                          class="d-flex justify-center align-center"
                        >
                          <div style="margin-bottom: 10px">Qty:</div>
                          <v-text-field
                            v-model="data.qty"
                            @change="addQuantity(index, key)"
                            autofocus
                            style="margin-left: 5px"
                            dense
                            small
                            type="number"
                            min="0.01"
                            single-line
                            :disabled="!data.budget_edit"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        height: 35px;
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 10px;
                      "
                    >
                      <div
                        style="
                          width: 180px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          margin-left: 5px;
                        "
                      >
                        <div
                          style="text-align: center"
                          class="d-flex justify-center align-center"
                        >
                          <div style="margin-bottom: 10px">uom:</div>
                          <v-autocomplete
                            v-model="data.uom_id"
                            :items="dropdownUnit"
                            clearable
                            item-text="name"
                            item-value="id"
                            hide-details
                            hide-no-data
                            return-id
                            style="margin-left: 5px; margin-bottom: 15px"
                            dense
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style="
                        height: 35px;
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 10px;
                      "
                    >
                      <v-divider class="mx-2" vertical></v-divider>
                      <v-toolbar-title
                        style="
                          width: 90px;
                          margin: 0 10px 0 0;
                          font-size: 12px;
                          color: rgba(0, 0, 0, 0.8);
                          font-weight: bold;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <span>Tgl. Digunakan</span> <span>:</span>
                      </v-toolbar-title>

                      <v-text-field
                        v-if="data.budget_edit"
                        v-model="data.raw_date"
                        style="height: 41px"
                        clearable
                        outlined
                        dense
                        type="date"
                        name="date"
                        step="1"
                      />
                      <p v-else style="margin: 0; padding: 0">
                        {{ convertDate(data.estimate_date_use) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </v-col>
      <v-col style="position: sticky; padding: 0 20px" cols="3">
        <div
          style="
            border-top: none;
            border: 2px solid rgba(219, 217, 217, 0.5);
            border-radius: 7px;
            position: relative;
            margin-top: 50px;
            padding: 10px;
          "
        >
          <div
            style="
              border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
              max-height: 60%;
            "
          >
            <p style="font-weight: bold; margin: 0; height: 40px">Ringkasan</p>
            <div
              style="
                margin-bottom: 10px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <p style="font-size: 13px; width: 50%; margin: 0">
                Total Budget ({{ count() }} barang)
              </p>
              <p
                style="
                  font-size: 13px;
                  text-align: right;
                  width: 50%;
                  margin: 0;
                "
              >
                Rp. {{ totalBudget() }}
              </p>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 70px;
              "
            >
              <p style="font-weight: bold; width: 50%; margin: 0">
                Total Budget
              </p>
              <p
                style="
                  font-weight: bold;
                  width: 50%;
                  text-align: right;
                  margin: 0;
                "
              >
                Rp. {{ totalBudget() }}
              </p>
            </div>
            <div
              v-if="!isDisableCompanyDropdown"
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-autocomplete
                label="- Plant -"
                v-model="company"
                :items="dropDownCompany"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
                @change="companyWatcher"
                :disabled="isDisableCompanyDropdown"
              ></v-autocomplete>
            </div>
            <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '26' || id === '30'
                ) !== undefined
              "
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-autocomplete
                label="- Project -"
                v-model="odoo_project_code"
                :items="dropdownProject"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
                @change="projectWatcher"
              ></v-autocomplete>
            </div>
            <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '26' || id === '30'
                ) !== undefined
              "
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-autocomplete
                label="- RAB -"
                v-model="budget_plan_id"
                :items="dropdownRAB"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
                @change="budgetplanWatcher"
              ></v-autocomplete>
            </div>
            <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '26' || id === '30'
                ) !== undefined
              "
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-autocomplete
                label="- kategori barang * -"
                v-model="category"
                :items="categoryDropdown"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
                @change="categoryItemWatcher"
              ></v-autocomplete>
            </div>
            <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '26' || id === '30'
                ) !== undefined
              "
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-autocomplete
                label="- kategori PR * -"
                v-model="category_pr"
                :items="[
                  { id: 'bahan_baku', name: 'Bahan Baku' },
                  { id: 'bahan_pendukung', name: 'Bahan Pendukung' },
                  { id: 'asset', name: 'Asset' },
                  { id: 'barang_khusus', name: 'Barang Khusus' },
                  { id: 'operasional', name: 'Operasional' },
                  { id: 'project', name: 'Project' },
                  { id: 'lain', name: 'Lain-lain' }
                ]"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
                @change="categoryPrWatcher"
                :disabled="
                  company != null &&
                  company.id == 5 &&
                  odoo_project_code != null
                "
              ></v-autocomplete>
            </div>
            <div
              v-if="odoo_project_code != null"
              style="
                width: 100%;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
              "
            >
              <v-text-field
                label="- No. RAB -"
                v-model="form.budget_plan_no"
                dense
                outlined
                style="margin: 0; height: 42px"
              />
            </div>
            <a @click="supportData" href="#" style="font-size: 12px"
              >Data pendukung !</a
            >
            <v-btn
              large
              style="
                width: 100%;
                color: white;
                font-size: 14px;
                font-weight: bold;
              "
              :loading="loading"
              color="primary"
              :disabled="select.length === 0 || loading"
              @click="submit"
            >
              Buat FPB
            </v-btn>
            <!-- <v-btn
              small
              class="mt-2"
              style="color: white; font-size: 12px; font-weight: bold"
              :loading="loading"
              color="primary"
              @click="createRab"
              :disabled="select.length === 0 || loading"
            >
              Buat RAB
            </v-btn> -->
          </div>
        </div>
        <div style="position: relative; padding: 0 12.5px" class="d-flex">
          <v-btn
            x-small
            style="color: white; font-size: 10px; font-weight: bold"
            :loading="loading"
            color="black"
            @click="addToExisting"
            :disabled="select.length === 0"
          >
            Add to existing
          </v-btn>
          <v-btn
            v-if="temporaryCart != null"
            x-small
            style="
              color: white;
              font-size: 10px;
              font-weight: bold;
              margin-left: 5px;
            "
            :loading="loading"
            color="blue"
            @click="undoCart"
          >
            Undo cart
          </v-btn>
          <v-btn
            x-small
            class="ml-2"
            style="color: white; font-size: 10px; font-weight: bold"
            :loading="loading"
            color="primary"
            @click="createRab"
            :disabled="select.length === 0"
          >
            Buat RAB
          </v-btn>
          <!-- <v-btn
            x-small
            style="color:white;font-size:10px;font-weight:bold;"
            :loading="loading"
            color="black"
            @click="addToExisting"
            :disabled="
              select.length === 0 ||
                select.find(({ budget }) => budget == 0) !== undefined ||
                loading
            "
          >
            Add to existing
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="rabDialog" width="500" persistent>
      <v-form ref="entryFormRab" @submit.prevent="saveRab">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            FORMULIR RAB
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-row no-gutters>
              <v-col md="12" style="padding: 0; margin-top: 10px">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama RAB
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      style="height: 45px"
                      dense
                      outlined
                      v-model="formRab.name"
                      :rules="rules.rabNameRules"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col md="12" style="padding: 0; margin-top: 20px">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Penanggung Jawab
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      :items="dropdownEmployee"
                      :search-input.sync="searchEmployee"
                      :rules="rules.rabEmployeeRules"
                      v-model="formRab.employee"
                      dense
                      clearable
                      outlined
                      item-text="name"
                      item-id="id"
                      hide-details
                      hide-no-data
                      return-object
                      style="height: 40px"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              type="reset"
              @click="rabDialog = false"
              :loading="loading"
            >
              Tutup
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <ExistingFpb
      @submit="submit"
      :proc="proc"
      :selected="select"
      :eSanqua="eSanqua"
    />
    <SupportData @initSupport="initSupport" />
    <v-snackbar
      bottom
      elevation="0"
      color="black"
      v-model="snackbar"
      :timeout="timeout"
    >
      <v-alert outlined type="warning" prominent border="left">
        {{ alertText }}
      </v-alert>
    </v-snackbar>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../services/buildType'
export default {
  components: {
    ExistingFpb: () => import('../../components/eCatalogue/cart/ExistingFpb'),
    SupportData: () => import('../../components/eCatalogue/cart/SupportData')
  },
  data: () => ({
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    loader: null,
    loading: false,
    isSelectAll: false,
    trigger: false,
    selectByVendor: [],
    select: [],
    items: null,
    itemsLength: 0,
    qty: 1,
    budget: 0,
    form: {
      reference_from_ecommerce: 0,
      budget_is_approved: 0,
      memo_special_request: 0,
      budget_plan_no: null
    },
    dropDownCompany: [],
    dropdownUnit: [],
    company: null,
    category: null,
    // categoryDropdown: [],
    dropdownProject: [],
    dropdownRAB: [],
    odoo_project_code: null,
    budget_plan_id: null,
    category_pr: null,
    snackbar: false,
    alertText: '',
    timeout: 2000,
    item_quantity: 0,
    rabDialog: false,
    searchEmployee: null,
    selectedEmployee: null,
    dropdownEmployee: [],
    rules: {
      rabNameRules: [],
      rabEmployeeRules: []
    },
    formRab: {
      name: '',
      employee: null
    },
    isDisableCompanyDropdown: false,
    temporaryCart: null
  }),
  mounted() {
    this.cartData()
    this.dropdown()
    this.unitDropdown()
    this.projectDropdown()
    this.temporaryCart = localStorage.getItem('temporarycart')
    this.budgetplanDropdown()
  },
  watch: {
    searchEmployee(val) {
      if (val !== '') {
        val && val !== this.selectedEmployee && this.querySelections(val)
      } else {
        this.selectedEmployee = null
        this.dropdownEemployee = []
      }
      this.odoo_project_code = null
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    categoryDropdown() {
      const arr = [
        { id: 1, name: 'Raw Material' },
        { id: 2, name: 'Factory Supply' },
        { id: 3, name: 'Office Supply' },
        { id: 4, name: 'Sparepart' },
        { id: 5, name: 'Jasa' },
        { id: 6, name: 'Maintenance Repair' },
        { id: 7, name: 'Investment / Asset' }
      ]
      if (this.company != null) {
        if (this.company.id == 5 || this.company.id == 14) {
          arr.splice(
            2,
            4,
            {
              id: 8,
              name: 'Office Supply (< 1jt)'
            },
            {
              id: 9,
              name: 'Office Supply (1jt-5jt)'
            },
            {
              id: 10,
              name: 'Office Supply (> 5j)'
            },
            {
              id: 11,
              name: 'Marketing Material (< 1jt)'
            },
            {
              id: 12,
              name: 'Marketing Material (1jt-5jt)'
            },
            {
              id: 13,
              name: 'Marketing Material (> 5j)'
            },
            {
              id: 14,
              name: 'Sparepart (< 1jt)'
            },
            {
              id: 15,
              name: 'Sparepart (1jt-5jt)'
            },
            {
              id: 16,
              name: 'Sparepart (> 5j)'
            },
            {
              id: 17,
              name: 'Jasa (< 1jt)'
            },
            {
              id: 18,
              name: 'Jasa (1jt-5jt)'
            },
            {
              id: 19,
              name: 'Jasa (> 5j)'
            },
            {
              id: 20,
              name: 'Maintenance Repair (< 1jt)'
            },
            {
              id: 21,
              name: 'Maintenance Repair (1jt-5jt)'
            },
            {
              id: 22,
              name: 'Maintenance Repair (> 5j)'
            }
          )
        }
      }
      return arr
    }
  },
  methods: {
    ...mapMutations(['loadTemporaryCart']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    querySelections(v) {
      axios
        .get(`${this.hrsApi}employee/dropdown?keyword=${v}`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdownEmployee = res.data.data)
          }
          return (this.dropdownEmployee = [])
        })
        .catch((err) => {
          this.dropdownEmployee = []
          return console.log(err)
        })
    },
    categoryItemWatcher(v) {
      if (v !== null) {
        if (v == 7) {
          this.category_pr = 'asset'
        } else {
          this.category_pr = null
        }
      } else {
        this.category_pr = null
      }
    },
    categoryPrWatcher(v) {
      if (v !== null) {
        if (v === 'project' || v === 'asset') {
          this.category = 7
        }
      } else {
        this.category = null
      }
    },
    projectWatcher(v) {
      if (v !== null) {
        this.category_pr = 'project'
        this.category = 7
      } else {
        this.category_pr = null
        this.category = null
      }
      console.log(v)
    },
    companyWatcher(v) {
      if (v !== null) {
        this.projectDropdown()
      }
      this.category_pr = null
      this.category = null
      this.odoo_project_code = null
    },
    budgetplanWatcher(v) {
      // if (v !== null) {
      //   this.category_pr = 'project'
      //   this.category = 7
      // }
      // console.log(v)
    },
    dropdown() {
      this.company = {
        id: Number(this.getUserProfile.employee.company.plant_id),
        name: this.getUserProfile.employee.company.name
      }
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '26'
      )
      console.log('level>>>>', level)
      if (level !== undefined) {
        console.log('level>>>>', level.user_user_level)
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropDownCompany = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropDownCompany = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    projectDropdown() {
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`
      // if (this.getUserProfile.employee.company.plant_id === '6') {
      if (!this.isDisableCompanyDropdown) {
        if (this.company !== null) {
          url = url + `&filter=[{"company_id":${this.company.id}}]`
        }
      } else {
        url =
          url +
          `&filter=[{"company_id":${this.getUserProfile.employee.company.plant_id}}]`
      }
      console.log(url)
      axios
        .get(url)
        .then((res) => {
          this.dropdownProject = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    budgetplanDropdown() {
      let url = `${this.proc}rab/dropdown?keyword=`
      if (this.getUserProfile.employee.company.plant_id === '6') {
        if (this.company !== null) {
          url = url + `&filter=[{"company_id":${this.company.id}}]`
        }
      } else {
        url =
          url +
          `&filter=[{"company_id":${this.getUserProfile.employee.company.plant_id}}]`
      }
      console.log(url)
      axios
        .get(url)
        .then((res) => {
          console.log('rab dropdown>>>', res)
          this.dropdownRAB = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    unitDropdown() {
      axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then((res) => {
          this.dropdownUnit = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cartData() {
      const arr = this.$store.state.cart.savedData
      const result = Array.from(new Set(arr.map((s) => s.vendor.name))).map(
        (ven) => {
          return {
            data: arr.filter((s) => s.vendor.name === ven)
          }
        }
      )
      console.log('Cart Data>>>>', result)
      this.items = result
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < result[i].data.length; j++) {
          this.itemsLength = this.itemsLength + 1
        }
      }
    },
    addToExisting() {
      document.getElementById('addExistingModal').click()
    },
    supportData() {
      document.getElementById('supportData').click()
    },
    initSupport(pItem) {
      this.form.reference_from_ecommerce = pItem.reference
      this.form.budget_is_approved = pItem.approvedBudget
      this.form.memo_special_request = pItem.memo
    },
    submit() {
      let xFlag = false
      let msg = ''
      if (this.select.length === 0) {
        // this.alertText =
        //   'Pilih minimal satu item untuk melanjutkan pembuatan FPB'
        this.loading = true
        this.showMsgDialog(
          'warning',
          'Pilih minimal satu item untuk melanjutkan pembuatan FPB',
          false
        )
        // this.snackbar = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
        return null
      }
      // if (this.category === null) {
      //   // this.alertText = 'Pilih katergori'
      //   this.loading = true
      //   this.showMsgDialog(
      //     'warning',
      //     'Kolom kategori tidak boleh kosong',
      //     false
      //   )
      //   // this.snackbar = true
      //   setTimeout(() => {
      //     this.loading = false
      //   }, 2000)
      //   return null
      // }
      const form = {
        act: 'add_batch_in_item',
        reference_from_ecommerce: this.form.reference_from_ecommerce,
        budget_is_approved: this.form.budget_is_approved,
        memo_special_request: this.form.memo_special_request,
        purchase_request_detail: [],
        category_item: this.category,
        project_id:
          this.odoo_project_code !== null ? this.odoo_project_code.id : null,
        budget_plan_id: this.budget_plan_id,
        category_pr: this.category_pr,
        budget_plan_no: this.form.budget_plan_no
      }

      // if (this.getUserProfile.employee.company.plant_id === '6') {
      if (!this.isDisableCompanyDropdown) {
        if (this.company === null) {
          // this.alertText = 'Pilih plant'
          this.loading = true
          // this.snackbar = true

          this.showMsgDialog('warning', 'Kolom plant tidak boleh kosong', false)
          setTimeout(() => {
            this.loading = false
          }, 2000)
          return null
        }
        Object.assign(form, {
          company_id: this.company.id,
          company_name: this.company.name,
          company_code: this.alias(this.company.id)
        })
      } else {
        Object.assign(form, {
          company_id: this.getUserProfile.employee.company.plant_id,
          company_name: this.getUserProfile.employee.company.name,
          company_code: this.alias(
            Number(this.getUserProfile.employee.company.plant_id)
          )
        })
      }

      for (let i = 0; i < this.select.length; i++) {
        const obj = {
          product_id: this.select[i].product.id,
          product_name: this.select[i].product.name,
          product_code: this.select[i].product.code,
          qty: this.select[i].qty,
          budget_price_per_unit: this.select[i].budget,
          vendor_id: this.select[i].vendor.clear_id,
          vendor_name: this.select[i].vendor.name,
          vendor_code: this.select[i].vendor.code,
          has_budget: 0,
          estimate_date_use: this.select[i].estimate_date_use,
          description:
            this.select[i].reason === ''
              ? this.select[i].product.name
              : this.select[i].reason,
          uom_id: this.select[i].uom_id,
          uom_name:
            this.select[i].uom_name !== null
              ? this.select[i].uom_name
              : this.findUomName(this.select[i].uom_id)
        }

        if (this.select[i].currency != null) {
          Object.assign(obj, {
            currency_id: this.select[i].currency.id,
            currency_code: this.select[i].currency.code,
            currency_symbol: this.select[i].currency.symbol
          })
        }

        form.purchase_request_detail.push(obj)
      }

      if (form.company_id != 5 && form.company_id != 14) {
        if (form.project_id !== null) {
          if (
            (form.category_pr === 'asset' && form.category_item === 7) ||
            (form.category_item === 7 && form.category_pr === 'project')
          ) {
            xFlag = true
            // this.saveFpb(form)
          } else {
            msg =
              'Bila Project, Kategori barang harus investment & karegori pr harus asset'
          }
        } else {
          if (form.category_pr === 'project') {
            msg = 'Kolom project tidak boleh kosong'
          } else {
            if (form.category_pr !== null) {
              if (form.category_pr === 'asset') {
                if (form.category_item !== null) {
                  if (form.category_item === 7) {
                    xFlag = true
                  } else {
                    msg = 'Kolom kategori barang harus investment / Asset'
                  }
                } else {
                  msg = 'Kolom kategori barang tidak boleh kosong'
                }
              } else {
                if (form.category_item !== null) {
                  if (form.category_item !== 7) {
                    xFlag = true
                  } else {
                    msg = 'Kolom kategori barang tidak boleh investment / Asset'
                  }
                } else {
                  msg = 'Kolom kategori barang tidak boleh kosong'
                }
              }
            } else {
              msg = 'Kolom kategori pr tidak boleh kosong'
            }
          }
        }

        if (xFlag) {
          if (form.category_pr === 'asset' || form.category_pr === 'project') {
            const notAssetCode = form.purchase_request_detail.find(
              ({ product_code }) => !product_code.includes('AST')
            )

            if (form.category_pr === 'asset' && notAssetCode !== undefined) {
              this.showMsgDialog(
                'warning',
                "Tidak dapat diproses karena ada kode barang yang bukan 'AST'",
                false
              )
            } else {
              this.saveFpb(form)
            }
          } else {
            const isAssetCode = form.purchase_request_detail.find(
              ({ product_code }) => product_code.includes('AST')
            )
            if (isAssetCode !== undefined) {
              this.showMsgDialog(
                'warning',
                "Tidak dapat diproses karena ada barang dengan kode 'AST'",
                false
              )
            } else {
              this.saveFpb(form)
            }
          }
        } else {
          this.showMsgDialog('warning', msg, false)
        }
      } else {
        this.saveFpb(form)
      }
    },
    saveFpb(form) {
      this.loading = true
      setTimeout(() => {
        axios
          .post(`${this.proc}fpb/save`, form)
          .then((res) => {
            console.log('save fpb', res)
            if (res.data.status_code === '00') {
              // if token expired when save fpb via cart create duplicate previous cart data
              if (res.data.token_data != undefined) {
                if (res.data.token_data.status_code == '-99') {
                  localStorage.setItem(
                    'temporarycart',
                    JSON.stringify(this.$store.state.cart.savedData)
                  )
                }
              }
              const created_id = res.data.created_id

              for (let i = 0; i < this.select.length; i++) {
                const ci = this.$store.state.cart.savedData.indexOf(
                  this.select[i]
                )
                this.$store.state.cart.savedData.splice(ci, 1)
              }
              // this.$store.state.cart.savedData = []
              this.$store.commit('setFpbId', created_id)

              this.loading = false
              this.$router.push('/e-catalogue/cart/fpb')
            } else {
              localStorage.setItem(
                'temporarycart',
                JSON.stringify(this.$store.state.cart.savedData)
              )
              this.loading = false
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          })
          .catch((err) => {
            localStorage.setItem(
              'temporarycart',
              JSON.stringify(this.$store.state.cart.savedData)
            )
            this.loading = false
            console.log(err)
          })
      }, 2000)
    },
    selectAll(event) {
      if (event) {
        this.select = []
        for (let i = 0; i < this.items.length; i++) {
          for (let j = 0; j < this.items[i].data.length; j++) {
            this.select.push(this.items[i].data[j])
          }
        }
      } else {
        this.select = []
      }
    },
    selectBy(event) {
      //   if (this.isSelectAll) {
      //     this.isSelectAll = false
      //   }
      //   this.select = []
      //   for (let i = 0; i < event.length; i++) {
      //     for (let j = 0; j < event[i].data.length; j++) {
      //       this.select.push(event[i].data[j])
      //     }
      //   }
    },
    selectOne(event) {
      if (this.itemsLength !== event.length) {
        this.isSelectAll = false
      } else {
        this.isSelectAll = true
      }
    },
    back() {
      this.$router.push('/e-catalogue/catalogue-list')
    },
    remove() {
      this.$store.state.cart.savedData = []
      this.select = []
      this.isSelectAll = false
      this.items = null
    },
    iBudgetEdit(i, k) {
      this.items[i].data[k].raw_budget = Number(this.items[i].data[k].budget)
      this.items[i].data[k].raw_reason = this.items[i].data[k].reason
      this.items[i].data[k].raw_date = this.items[i].data[k].estimate_date_use
      this.items[i].data[k].budget_edit = true
      this.cartData()
    },
    iBudgetSave(i, k) {
      this.items[i].data[k].budget_edit = false

      this.items[i].data[k].budget = Number(this.items[i].data[k].raw_budget)
      this.items[i].data[k].reason = this.items[i].data[k].raw_reason
      this.items[i].data[k].estimate_date_use = this.items[i].data[k].raw_date
      this.cartData()
    },
    iBudgetCancel(i, k) {
      this.items[i].data[k].budget_edit = false
      delete this.items[i].data[k].raw_budget
      delete this.items[i].data[k].raw_reason
      delete this.items[i].data[k].raw_date
      this.cartData()
    },

    iDelete(data, i, k) {
      setTimeout(() => {
        if (this.items[i].data.length > 1) {
          this.items[i].data.splice(k, 1)
          this.itemsLength = this.itemsLength - 1
        } else {
          this.items[i].data.splice(k, 1)
          this.items.splice(i, 1)
          this.itemsLength = this.itemsLength - 1
        }

        if (this.$store.state.cart.savedData.length > 0) {
          const ci = this.$store.state.cart.savedData.indexOf(data)
          this.$store.state.cart.savedData.splice(ci, 1)
        }
        if (this.select.length > 0) {
          const ci = this.select.indexOf(data)
          this.select.splice(ci, 1)
        } else {
          this.select = []
        }
      }, 300)
    },
    addQuantity(i, k) {
      // if (Number(this.items[i].data[k].qty) < 1) {
      //   this.items[i].data[k].qty = 1
      // } else {
      this.items[i].data[k].qty = Number(this.items[i].data[k].qty)
      // }
      this.cartData()
    },
    // qtyColumnFocus(i, k) {
    //   console.log(i, k)
    // },
    // increase(i, k) {
    //   this.items[i].data[k].qty = this.items[i].data[k].qty + 1
    //   this.cartData()
    // },
    // reduce(i, k) {
    //   if (this.items[i].data[k].qty > 0) {
    //     this.items[i].data[k].qty = this.items[i].data[k].qty - 1
    //     this.cartData()
    //   }
    // },
    createRab() {
      if (this.select.length === 0) {
        this.alertText =
          'Pilih minimal satu item untuk melanjutkan pembuatan FPB'
        this.loading = true
        this.snackbar = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
        return null
      }

      setTimeout(() => {
        this.rabDialog = true
      }, 300)
    },
    closeRabDialog() {
      this.formRab.name = ''
      this.formRab.employee = null
      this.$refs.entryFormRab.reset()
      setTimeout(() => {
        this.rabDialog = false
      }, 300)
    },
    saveRab() {
      console.log(this.formRab)
      console.log(this.odoo_project_code, this.company)
      const self = this
      this.rules.rabNameRules = [(v) => !!v || 'Nama rab harus diisi']
      this.rules.rabEmployeeRules = [
        (v) => !!v || 'Penanggung jawab harus diisi'
      ]

      setTimeout(function () {
        if (self.$refs.entryFormRab.validate()) {
          self.newFormRab()
        }
      })
    },
    newFormRab() {
      const form = {
        act: 'add_batch_in_item',
        name: this.formRab.name,
        project_id:
          this.odoo_project_code !== null ? this.odoo_project_code.id : null,
        project_name:
          this.odoo_project_code !== null ? this.odoo_project_code.name : null,
        project_code:
          this.odoo_project_code !== null ? this.odoo_project_code.code : null,
        pic_employee_id: Number(this.formRab.employee.id),
        pic_employee_nik: this.formRab.employee.nik,
        pic_employee_name: this.formRab.employee.name,
        // company_id: this.company.id,
        // company_name: this.company.name,
        // company_code: this.alias(this.company.id),
        budget_plan_detail: []
      }

      if (this.getUserProfile.employee.company.plant_id === '6') {
        if (this.company === null) {
          this.alertText = 'Pilih plant'
          this.loading = true
          this.snackbar = true
          setTimeout(() => {
            this.loading = false
          }, 2000)
          return null
        }
        Object.assign(form, {
          company_id: this.company.id,
          company_name: this.company.name,
          company_code: this.alias(this.company.id)
        })
      } else {
        Object.assign(form, {
          company_id: this.getUserProfile.employee.company.plant_id,
          company_name: this.getUserProfile.employee.company.name,
          company_code: this.alias(
            Number(this.getUserProfile.employee.company.plant_id)
          )
        })
      }

      for (let i = 0; i < this.select.length; i++) {
        const obj = {
          product_id: this.select[i].product.id,
          product_name: this.select[i].product.name,
          product_code: this.select[i].product.code,
          qty: this.select[i].qty,
          budget_price_per_unit: this.select[i].budget,
          vendor_id: this.select[i].vendor.clear_id,
          vendor_name: this.select[i].vendor.name,
          vendor_code: this.select[i].vendor.code,
          estimate_date_use: this.select[i].estimate_date_use,
          description:
            this.select[i].reason === ''
              ? this.select[i].product.name
              : this.select[i].reason,
          type: '',
          merk: '',
          dimension: '',
          material: ''
        }
        if (this.select[i].currency != null) {
          Object.assign(obj, {
            currency_id: this.select[i].currency.id,
            currency_symbol: this.select[i].currency.symbol,
            currency_code: this.select[i].currency.code
          })
        }

        form.budget_plan_detail.push(obj)
      }

      this.submitRab(form)
    },
    submitRab(form) {
      console.log('payload>>>>', form)
      this.loading = true
      axios
        .post(`${this.proc}rab/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const created_id = res.data.created_id

            for (let i = 0; i < this.select.length; i++) {
              const ci = this.$store.state.cart.savedData.indexOf(
                this.select[i]
              )
              this.$store.state.cart.savedData.splice(ci, 1)
            }
            this.$store.state.cart.savedData = []
            // this.$store.commit('setFpbId', created_id)

            // this[l] = false
            this.$router.push(`/e-catalogue/rab/detail/${created_id}`)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    totalBudget() {
      let total = 0
      for (let i = 0; i < this.select.length; i++) {
        total = total + Number(this.select[i].budget * this.select[i].qty)
      }
      // this.totalBudget = total
      return this.price(total)
    },
    count() {
      let total = 0
      for (let i = 0; i < this.select.length; i++) {
        total = total + this.select[i].qty
      }
      return this.price(total)
    },
    findUomName(val) {
      if (val !== null) {
        return this.dropdownUnit.find(({ id }) => id === val).name
      }
    },
    price(val) {
      const reverse = Math.round(val).toString().split('').reverse().join('')
      var ribuan = reverse.match(/\d{1,3}/g)
      return ribuan.join(',').split('').reverse().join('')
    },

    convertDate(raw) {
      if (raw !== undefined) {
        if (raw !== null) {
          const year = raw.slice(0, raw.indexOf('-'))
          let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
          const day = raw.slice(raw.lastIndexOf('-') + 1)
          switch (month) {
            case '01':
              month = 'Jan'
              break
            case '02':
              month = 'Feb'
              break
            case '03':
              month = 'Mar'
              break
            case '04':
              month = 'Apr'
              break
            case '05':
              month = 'May'
              break
            case '06':
              month = 'Jun'
              break
            case '07':
              month = 'Jul'
              break
            case '08':
              month = 'Aug'
              break
            case '09':
              month = 'Sep'
              break
            case '10':
              month = 'Oct'
              break
            case '11':
              month = 'Nov'
              break
            case '12':
              month = 'Des'
              break
          }

          return day + ' ' + month + ' ' + year
          // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        }
      }
    },
    alias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
        case 16:
          return 'GAP'
        case 17:
          return 'GITP'
      }
    },
    undoCart() {
      // console.log(localStorage.getItem('temporarycart'))
      // console.log(JSON.parse(localStorage.getItem('temporarycart')))
      this.loadTemporaryCart()
    }
  }
}
</script>
<style lang="scss" scoped>
.cart-back-icon {
  &:hover {
    cursor: pointer;
    .icon {
      color: black;
    }
    span {
      color: black;
      font-weight: bold;
    }
  }
}
</style>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
